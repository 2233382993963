<template>
  <div class="Part">
    <div class="input">
      <!-- 功能按钮 -->
      <el-button type="primary" size="mini" icon="el-icon-plus" @click="add" v-if="authe.Hotel_Personnel_Roles_AddTo">添加</el-button>

      <!-- 表格筛选 -->
      <el-row style="padding: 5px 5px 5px 5px;">
        角色名 &nbsp;&nbsp;
        <el-input v-model="scFrom.name" @input="scMethod" clearable size="mini" style="width:125px"></el-input>&nbsp;&nbsp;
      </el-row>
    </div>
    <div style="text-align:center;">
      <el-table :data="tableData" border :height="tableHeight" highlight-current-row>
        <el-table-column prop="srRoleName" label="角色名" align="center"></el-table-column>
        <el-table-column prop="srRemark" label="备注" align="center"></el-table-column>
        <el-table-column prop="srCreateTime" label="创建时间" align="center"></el-table-column>
        <el-table-column prop="srUpdateTime" label="修改时间" align="center"></el-table-column>
        <el-table-column label="操作" align="center" min-width="130px">
          <template slot-scope="scope">
            <el-button size="mini" @click="authorization(scope.$index, scope.row)">菜单授权</el-button>
            <el-button size="mini" @click="handleChange(scope.$index, scope.row)" v-if="authe.Hotel_Personnel_Roles_Modify">修改</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" v-if="authe.Hotel_Personnel_Roles_Delete">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        :total="total"
        :page-size="pageSize"
        :page-sizes="[5,10,20,40]"
        :current-page="currentPage"
        layout="total, sizes, prev, pager, next, jumper"
        background
        @size-change="sizeChange($event,'pageSize',getAllUser,'clickrow')"
        @current-change="currentChange($event,'currentPage',getAllUser,'clickrow')"
      ></el-pagination>
    </div>
    <!-- 添加修改角色 -->
    <el-dialog
      :title="addFrom.title"
      :visible.sync="addDia"
      width="400px"
      :close-on-click-modal="false"
      @closed="addClose"
    >
      <el-form ref="addFrom" :model="addFrom" label-width="80px" size="small">
        <el-form-item label="角色名">
          <el-input v-model="addFrom.name"></el-input>
        </el-form-item>
        <el-form-item label="上级角色" prop="superiorMenu">
          <el-select v-model="addFrom.supId" style="width:100%">
            <el-option label="无上级角色" value="0"></el-option>
            <el-option
              v-for="item in addFrom.opt"
              v-show="addFrom.id !== item.srId"
              :key="item.srId"
              :label="item.srRoleName"
              :value="item.srId.toString()"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="addFrom.remark"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDia = false">取 消</el-button>
        <el-button type="primary" @click="addSave">保 存</el-button>
      </span>
    </el-dialog>
    <!-- 菜单授权 -->
    <!-- 权限对话框 -->
    <el-dialog
      title="权限管理"
      :visible.sync="authorityDia"
      width="1000px"
      :close-on-click-modal="false"
    >
      <div>
        <el-button size="mini" icon="el-icon-view" @click="expandAll">全部展开</el-button>
        <el-button size="mini" icon="el-icon-copy-document" @click="collapseAll">全部折叠</el-button>
        <el-button size="mini" icon="el-icon-circle-check" @click="allOnOff(1)">全开</el-button>
        <el-button size="mini" icon="el-icon-circle-close" @click="allOnOff(0)">全关</el-button>
      </div>
      <br />
      <el-table
        :data="authorityData"
        row-key="smId"
        border
        ref="theTable"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column
          label="顺序"
          type="index"
          align="center"
          width="80"
          :index="indexMethod">
        </el-table-column>
        <el-table-column prop="smMenuName" label="菜单名称" align="center"></el-table-column>
        <el-table-column label="权限" align="center" width="180">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.swich"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="Number('1')"
              :inactive-value="Number('0')"
              @change="swichChange($event,scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="authorityDia = false">取 消</el-button>
        <el-button type="primary" @click="authorSave">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapActions,mapState} from 'vuex'
export default {
  name: "roleManagement",
  data() {
    return {
      addDia: false,
      authorityDia: false,
      
      addFrom: {
        title: "",
        name: "",
        remark: "",
        supId: "",
        opt: []
      },
      scFrom: {
        name: ""
      },
      tableData: [],
      clickrow: '',
      tableHeight: window.innerHeight - 225,

      authorityData: [],
      // 首页分页
      total: 0,
      pageSize: 10,
      currentPage: 1
    };
  },
  computed: {
    ...mapState(['authe'])
  },
  created() {
    this.getAllUser();
  },
  methods: {
    ...mapActions(['getMenu','getAuthority','getPageAuth']),
    //添加修改
    async add() {
      this.addFrom.title = "添加角色";
      await this.$axios({
        url: '/common1/roles',
        method: 'get',
        params: {
          cursor: 1,
          limit: 100000,
          srRoleName: ''
        }
      }).then(res => {
        this.addFrom.opt = res.data.result.records
        console.log(this.addFrom.opt);
      }).catch(err => {
        this.isError(err,this)
      })
      this.addDia = true;
    },
    //保存添加
    addSave() {
      if (this.addFrom.title == "添加角色") {
        if (this.addFrom.supId == "") {
          this.addFrom.supId = 0
        }
        this.$axios({
          url: "/common1/roles",
          method: "post",
          data: {
            menuIds: [],
            srCreateTime: "",
            srCreateUid: 0,
            srId: 0,
            srRemark: this.addFrom.remark,
            srRoleName: this.addFrom.name,
            srUpdateTime: "",
            srUpdateUid: 0,
            srParentId: this.addFrom.supId
          }
        }).then(res => {
          this.$message.success("保存成功");
          this.getAllUser();
          this.addDia = false;
        }).catch(err => {
          this.isError(err,this)
        });
      } else {
        this.$axios({
          url: `/common1/roles/${this.addFrom.id}`,
          method: "put",
          data: {
            srRemark: this.addFrom.remark,
            srRoleName: this.addFrom.name,
            srParentId: this.addFrom.supId
          }
        }).then(res => {
          this.$message.success("保存成功");
          this.getAllUser();
          this.addDia = false;
        }).catch(err => {
          this.isError(err,this)
        });
      }
    },
    //保存关闭
    addClose() {
      this.addFrom = {
        title: "",
        name: "",
        remark: "",
        supId: "",
        opt: []
      };
    },
    //授权
    async authorization(index, row) {
      this.clickrow = row
      this.authorityData = this.transDate(
        await this.getClientAuthorMenu(row.srId),
        "smId",
        "smParentId"
      );
      this.authorityDia = true
    },
    //授权保存
    authorSave(){
      let data = this.treeTransArray(this.authorityData);
      let arr = [];
      for (const item of data) {
        if (item.swich == 1) {
          arr.push(item.smId);
        }
      }
      console.log(arr);
      this.$axios({
        url: `/common1/roleMenus/${this.clickrow.srId}`,
        method: "put",
        data: {
          srmMenuIds: arr
        }
      }).then(res => {
        this.$message.success("保存成功");
        this.authorityDia = false;
        this.getMenu()
        this.getAuthority()
        this.getPageAuth()
      }).catch(err => {
        this.isError(err,this)
      });
    },
    //修改
    async handleChange(index, row) {
      this.addFrom.title = "修改角色";
      console.log(row);
      
      await this.$axios({
        url: '/common1/roles',
        method: 'get',
        params: {
          cursor: 1,
          limit: 100000,
          srRoleName: ''
        }
      }).then(res => {
        this.addFrom.opt = res.data.result.records
        console.log(this.addFrom.opt);
      }).catch(err => {
        this.isError(err,this)
      })
      this.addFrom.name = row.srRoleName;
      this.addFrom.remark = row.srRemark;
      this.addFrom.supId = row.srParentId
      this.addFrom.id = row.srId;
      this.addDia = true;
      console.log(index, row);
    },
    //删除
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm("确定删除该记录吗？").then(() => {
        this.$axios({
          url: `/common1/roles/${row.srId}`,
          method: "delete"
        }).then(res => {
          this.$message.success("删除成功");
          this.getAllUser();
        }).catch(err => {
          this.isError(err,this)
        })
      });
    },
    getClientAuthorMenu(id) {
      return this.$axios({
        url: `/common1/menus/${id}`,
        method: "get",
      }).then(res => {
        // return this.transDate(res.data.result, "smId", "smParentId");
        return res.data.result;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //查询所有角色
    getAllUser() {
      this.$axios({
        url: "/common1/roles",
        method: "get",
        params: {
          cursor: this.currentPage,
          limit: this.pageSize,
          srRoleName: this.scFrom.name
        }
      }).then(res => {
        this.tableData = res.data.result.records;
        this.total = res.data.result.total;
      }).catch(err => {
        this.isError(err,this)
      })
    },
    //筛选
    scMethod() {
      this.currentPage = 1;
      this.getAllUser();
    },
    //选择数据条数
    sizeChange(callVal, pageSize, getData, ...cleanRow) {
      this.$data[pageSize] = callVal;
      this.$data[cleanRow] = "";
      getData();
    },
    //具体那一页
    currentChange(callVal, currentPage, getData, ...cleanRow) {
      this.$data[currentPage] = callVal;
      this.$data[cleanRow] = "";
      getData();
    },
    //json数组转树结构
    transDate(list, idstr, pidstr) {
      let result = [];
      let temp = {};
      for (let i = 0; i < list.length; i++) {
        temp[list[i][idstr]] = list[i]; //将nodes数组转成对象类型
      }
      for (let j = 0; j < list.length; j++) {
        let tempVp = temp[list[j][pidstr]]; //获取每一个子对象的父对象
        if (tempVp) {
          //判断父对象是否存在，如果不存在直接将对象放到第一层
          if (!tempVp["children"]) tempVp["children"] = []; //如果父元素的nodes对象不存在，则创建数组
          tempVp["children"].push(list[j]); //将本对象压入父对象的nodes数组
        } else {
          result.push(list[j]); //将不存在父对象的对象直接放入一级目录
        }
      }
      return result;
    },
    //树结构返回普通json数组
    treeTransArray(nodes) {
      let r = [];
      if (Array.isArray(nodes)) {
        for (let i = 0, l = nodes.length; i < l; i++) {
          r.push(nodes[i]); // 取每项数据放入一个新数组
          if (
            Array.isArray(nodes[i]["children"]) &&
            nodes[i]["children"].length > 0
          )
            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
            r = r.concat(this.treeTransArray(nodes[i]["children"]));
          delete nodes[i]["children"];
        }
      }
      return r;
    },
    //全部展开
    expandAll() {
      this.forArr(this.authorityData, true);
    },
    //全部折叠
    collapseAll() {
      this.forArr(this.authorityData, false);
    },
    forArr(arr, isExpand) {
      for (const i of arr) {
        if ("children" in i) {
          this.$refs.theTable.toggleRowExpansion(i, isExpand);
          this.forArr(i.children, isExpand);
        }
      }
    },
    allOnOff(state) {
      let data = this.treeTransArray(this.authorityData);
      for (const item of data) {
        item.swich = state;
      }
      this.authorityData = this.transDate(data, "smId", "smParentId");
    },
    indexMethod(index) {
        return index + 1;
    },
    //权限开关
    swichChange(val, row) {
      console.log(val, row);
      let arr = JSON.stringify(this.setSwich([row],val))
      row = JSON.parse(arr.substring(1,arr.length-1))//QU []
      if (row.smParentId != 0 && val == 1) {//子级打开父级
        let res = this.getParent(this.authorityData, row.smId)
        let parentId = res.slice(0, res.length - 1)
        let i = 0;
        for (const item of this.authorityData) {
            if (item.smId == parentId[i]) {
              item.swich = 1
              this.openKKK(item.children, i, parentId)
              break;
            }
        }
      }
    },
    setSwich(data,val){ //遍历树  获取id数组
      for(let item of data){
        item.swich = val
        if(item.children){
          this.setSwich(item.children,val);
        }
      }
      return data
    },
    openKKK(item, i, parentId) {
        i++;
        for (const item1 of item) {
          if (item1.smId == parentId[i]) {
            item1.swich = 1
          }
        }
        if (i == parentId.length-1) {
            return;
        }
        for (const item2 of item.children) {
            this.openKKK(item2, i, parentId);
        }
    },
    getParent(data2, nodeId2) {
        var arrRes = [];
        if (data2.length == 0) {
            if (!!nodeId2) {
                arrRes.unshift(data2)
            }
            return arrRes;
        }
        let rev = (data, nodeId) => {
            for (let i = 0, length = data.length; i < length; i++) {
                let node = data[i];
                if (node.smId == nodeId) {
                    arrRes.unshift(node.smId)
                    rev(data2, node.smParentId);
                    break;
                }
                else {
                    if (!!node.children) {
                        rev(node.children, nodeId);
                    }
                }
            }
            return arrRes;
        };
        arrRes = rev(data2, nodeId2);
        return arrRes;
    }
  },
};
</script>

<style>
</style>